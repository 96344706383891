<template>
  <div class="replace_container">
    <div class="img">
      <img src="@/assets/img/zone/hg.png" alt="" />
    </div>
    <div class="content">
      <div class="coupon">
        <div class="top">优惠券 <span>下单先领券、优惠多</span></div>
        <div class="cou_wrap">
          <div class="cou_item" v-for="item in 2" :key="item">
            <img src="@/assets/img/zone/yhq.png" alt="" />
            <div class="box">
              <p class="pr">￥<span>100</span></p>
              <div class="flex_1">
                <div class="p1">订单满1000.00可用</div>
                <div>2023/02/17至2024/02/17</div>
                <div class="btn">立即领取</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="title">换购专区</div>
      <div class="goods_wrap">
        <div class="item" v-for="item in 5" :key="item">
          <bigimg />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bigimg from "@/components/goodsItem/bigImg.vue";

export default {
  components: {
    bigimg,
  },
};
</script>

<style lang="less" scoped>
.replace_container {
  background: #fd4b4c;
}
.img {
  width: 100%;
  height: 375px;
}
.coupon {
  width: 100%;
  padding: 20px;
  padding-bottom: 5px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 4px;
  .top {
    font-size: 18px;
    font-weight: bold;
    color: #993715;
    padding-bottom: 20px;
    border-bottom: 1px solid #993715;
    span {
      font-size: 14px;
      font-weight: 400;
      margin-left: 20px;
    }
  }
  .cou_wrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    .cou_item {
      width: 357px;
      height: 126px;
      position: relative;
      margin-right: 25px;
      margin-bottom: 25px;
      img {
        position: absolute;
      }
      .box {
        width: 357px;
        height: 126px;
        position: absolute;
        display: flex;
        align-items: center;
        .pr {
          width: 140px;
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          span {
            font-size: 46px;
          }
        }
        .flex_1 {
          text-align: center;
          color: #ffffff;
          .p1 {
            font-size: 20px;
            margin-bottom: 6px;
          }
          .btn {
            width: 110px;
            height: 32px;
            background: #ffd673;
            border-radius: 16px;
            line-height: 32px;
            margin: 0 auto;
            font-size: 16px;
            font-weight: bold;
            color: #993715;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
.title {
  width: 100%;
  height: 58px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  color: #993715;
  padding-left: 20px;
  line-height: 58px;
  box-sizing: border-box;
  margin: 10px 0;
}
.goods_wrap {
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-bottom: 12px;
    margin-right: 12px;
    &:nth-child(5n) {
      margin-right: 0;
    }
  }
}
</style>
